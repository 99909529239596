// Translated
// Migrated
<template lang="pug">
.container-fluid.booking-wrap
  .row
    ScreenWidthProvider.col-12.col-lg-5.p-0(v-slot="{ isWider }")
      ResponsiveImage.article-block-img.d-flex.flex-column.h-100.w-100(
        v-if="isWider('md')"
        :image="image"
        alt="Calendar"
        cover
      )

    .col-12.col-lg-7.booking-content
      h4.calendar-coming-departures-title.mb-0.mb-xl-2.mx-2.mx-md-0(
        data-i18n="upcomingDepartures"
      ) {{ $t('upcomingDepartures') }}
      .booking-content-items
        CalendarTable.mb-3(:trips="trips")
        nuxt-link.w-100.text-center.text-md-left.d-block(
          :to="localeURLs.calendar",
          data-i18n="seeCalendarLink"
        ) {{ $t('seeCalendarLink') }} >
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs
    }
  },

  data () {
    return {
      image: 'v1688126803/general/booking-bg-2',
      sizeList: [{
        maxWidth: null,
        size: 1280 * 1.5
      }, {
        maxWidth: 992,
        size: 992 * 2
      }, {
        maxWidth: 600,
        size: 800 * 1.5
      }],
    }
  },

  computed: {
    ...mapState(useRootStore, {
      trips: 'startCalendar'
    }),
  }
})
</script>

<style lang="scss">
@import "@/assets/scss/modules/main-page/_booking";
.booking-wrap {
  .calendar-coming-departures-title {
    font-size: 1.25em;
  }

  .article-block-img {
    @media (max-width: $lg) {
      height: 250px !important;
    }
  }
}
</style>
